const validator = {
  // 用户名验证
  username (rules, value, callback) {
    var reg = new RegExp(/^[\u4e00-\u9fa5_a-zA-Z0-9]{1,30}$/)
    if (!value) {
      return callback(new Error('用户名不能为空'));
    } else {
      if ((value && !reg.test(value))) {
        callback(new Error('用户名不能包含空格和特殊字符，且在1到30个字符之间'))
      } else {
        callback()
      }
    }
  },
  // 密码验证
  password (rules, value, callback) {
    var reg = new RegExp('^(?![A-Za-z0-9]+$)(?![a-z0-9\\W]+$)(?![A-Za-z\\W]+$)(?![A-Z0-9\\W]+$)[a-zA-Z0-9\\W]{8,20}$')
    const regs = new RegExp(/\s/g)
    if (!value) {
      return callback(new Error('密码不能为空'));
    } else {
      // //console.log()
      if (regs.test(value) || !reg.test(value)) {
        callback(new Error('请输入8位及以上的大小写字母、数字、特殊字符的混合表达式，且不能输入空格和中文'))
      } else {
        callback()
      }
    }
  },
  // 输入框字数验证
  inputValidator (rule, value, callback) {
    // //console.log(value, Number)
    const len = value.replace(/[^\x00-\xff]/g, 'aa').length // eslint-disable-line
    if (len > 200) {
      callback(new Error(`最多可填写200个字符`))
    } else {
      callback()
    }
  },
  // 邮箱验证
  email (rule, value, callback) {
    var reg = new RegExp(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]{2,6})+$/)// eslint-disable-line
    if (!value) {
      return callback(new Error('邮箱不能为空！'));
    } else {
      if ((value && !reg.test(value))) {
        callback(new Error('请输入正确邮箱！'))
      } else {
        callback()
      }
    }
  },
  // 手机号验证
  phone (rule, value, callback) {
    if (!value) {
      return callback(new Error('请输入手机号码'));
    } else {
      if (value && !(/^[1][3-9][0-9]{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号码'))
        return false
      } else {
        callback()
      }

    }
  },
  // 非必填手机号验证
  phones (rule, value, callback) {
    if (!value) {
      callback()
    } else {
      if (value && !(/^[1][3-9][0-9]{9}$/.test(value))) {
        callback(new Error('请输入正确的手机号码'))
        return false
      } else {
        callback()
      }

    }
  },
  // 身份证号校验
  validateIdCard (rule, idCard, callback) {
    var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

    //如果通过该验证，说明身份证格式正确，但准确性还需计算
    if (regIdCard.test(idCard)) {
      if (idCard.length == 18) {
        var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //将前17位加权因子保存在数组里
        var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
        var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
        for (var i = 0; i < 17; i++) {
          idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
        }

        var idCardMod = idCardWiSum % 11;//计算出校验码所在数组的位置
        var idCardLast = idCard.substring(17);//得到最后一位身份证号码
        //console.log(idCardMod, idCardLast, idCardY[idCardMod])
        //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
        if (idCardMod == 2) {

          if (idCardLast == "X" || idCardLast == "x") {

            callback()
          } else {

            callback(new Error('请输入正确的身份证号码'))
          }
        } else {

          //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
          if (Number(idCardLast) == idCardY[idCardMod]) {

            callback()
          } else {

            callback(new Error('请输入正确的身份证号码'))
          }
        }
      }
    } else {

      callback(new Error('请输入正确的身份证号码'))
    }
  },
  // 行政区code验证
  areaCode (rule, value, callback) {
    var reg = new RegExp(/^[1-8][0-7]\d{4}$/)
    if (!value) {
      return callback(new Error('请输入行政区号'));
    }
    if ((value && !reg.test(value))) {
      callback(new Error('请输入正确行政区号！'))
    } else {
      callback()
    }
  },
  // 出生日期验证
  Birthdate (rule, value, callback) {
    var reg = new RegExp(/^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/)
    if (!value) {
      return callback(new Error('请输入出生日期'));
    }
    if ((value && reg.test(value))) {
      var year = value.substring(0, 4);
      var month = value.substring(4, 6);
      var date = value.substring(6, 8);
      var date2 = new Date(year + "-" + month + "-" + date);
      if (date2 && date2.getMonth() == (parseInt(month) - 1)) {
        callback()
      } else {
        callback(new Error('请输入正确出生日期 例19900101'))
      }
    } else {
      callback(new Error('请输入正确出生日期 例19900101'))
    }
  },
  // 检测特殊字符-
  string (rule, value, callback) {
    // eslint-disable-next-line
    const reg = new RegExp(/[`~!@#$%^&*()_\+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】、；‘'，。、]/im, 'g')
    if ((value && reg.test(value))) {
      callback(new Error('输入框不能输入特殊字符'))
    } else {
      callback()
    }
  },
  //检测特殊字符
  special (rule, value, callback) {
    // eslint-disable-next-line
    const reg = new RegExp(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im, 'g')
    if ((value && reg.test(value))) {
      callback(new Error('输入框不能输入特殊字符'))
    } else {
      callback()
    }
  },
  chinese (rule, value, callback) {
    const reg = new RegExp(/[\u4E00-\u9FA5]/g)
    if ((value && reg.test(value))) {
      callback(new Error('不能包含中文字符'))
    } else {
      callback()
    }
  }
}

export default validator
