import VueRouter from 'vue-router'

// import Unit from '../components/Unit/unit'
// import Alarm from '../components/Alarm/alarm'
// import Detail from '../components/Detail/airdetail'
// import Reportlist from '../components/reportlist'
// import Record from '../components/record'
// import Statistics from '../components/statistics'
// import Device from '../components/Device/airDevice'
// import User from '../components/User/index'
// import Power from '../components/power'
// import Log from '../components/log'
// import Person from '../components/person'
// import Login from '../components/login'
// import Logins from '../components/logins'
import Layout from '../components/Layout'
// import Reportcont from '../components/report'
// import Camera from '../components/Camera/camera'
// import BackCamera from '../components/Camera/backcamera'
// import System from '../components/system'
// import airdetail from '../components/Detail/detail'


export default new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  routes: [
    {
      path: '/',
      component: () => import('../components/login'),
      // redirect: '/login'
    },
    {
      path: '/logins',
      component: () => import('../components/logins'),
      // redirect: '/login'
    },
    {
      path:'/signin',
      component:()=>import('../components/signin.vue')
    },
    {
      path: '',
      component: Layout,
      redirect: '/unit',
      hidden: true,
      children: [
        {
          path: '/unit',
          component: () => import('../components/Unit/unit')
        }
        , {
          path: '/alarm',
          component: () => import('../components/Alarm/alarm')
        }
        ,
        {
          path: '/detail',
          component: () => import('@/components/Operation'),
        },
        {
          path: '/report',
          component: () => import('../components/report')
        }
        ,
        {
          path: '/reportlist',
          component: () => import('../components/reportlist')
        }
        ,
        {
          path: '/airdetail',
          component: () => import('../components/Detail/detail')
        }
        ,
        {
          path: '/record',
          component: () => import('../components/record')
        }
        ,
        {
          path: '/statistics',
          component: () => import('../components/statistics')
        }
        ,
        {
          path: '/device',
          component: () => import('../components/Device/airDevice')
        }
        ,
        {
          path: '/user',
          component: () => import('../components/User/index')
        }
        ,
        {
          path: '/camera',
          component: () => import('../components/Camera/camera')
        },
        {
          path: '/backcamera',
          component: () => import('../components/Camera/backcamera')
        }
        ,
        {
          path: '/power',
          component: () => import('../components/power')
        }
        ,
        {
          path: '/log',
          component: () => import('../components/log')
        }
        ,
        {
          path: '/person',
          component: () => import('../components/person')
        }
        ,
        {
          path: '/system',
          component: () => import('../components/system')
        },
        {
          path: '/risk',
          component: () => import('../components/risk/index')
        }


      ]
    },

  ]
})
