<template>
  <div id="layout">
    <el-container>
      <el-header style="height:70px">
        <Header></Header>
      </el-header>
      <el-container class="tableleft">
        <el-aside width="240px">
          <Sidebar></Sidebar>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Sidebar from "./sidebar.vue";
import Header from "./Header.vue";
export default {
  components: {
    Sidebar,
    Header
    // HelloWorld,
    // Home
  },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped >
a {
  text-decoration: none;
}

.el-main {
  padding-top: 0;
}

.tableleft {
  padding-left: 20px;
  padding-top: 20px;
}

.el-header {
  padding: 0;
}

.el-aside {
  min-height: 90vh;
  background: white;
}

.el-card__header {
  padding: 20px 20px 10px 20px;
}

.el-menu-item {
  font-size: 16px;
  padding: 0 !important;
  text-align: center;
}


</style>
