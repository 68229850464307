<template>
  <div class="headermain">
    <div class="headerleft">
      <img src="https://statics.terabits.cn/mainimg/child.png" alt class="logoimg" />
      婴幼儿沐浴场所消毒卫生监管平台
    </div>
    <div class="headerright">
      <span style="margin-right:10px">欢迎您，</span>
      <span>{{name||"超级管理员"}}</span>
      <img src="../images/out.png" alt class="outimg" />
      <span @click="out" style="cursor:pointer;margin-left:10px">
        <!-- <img alt  /> -->
        退出
      </span>
    </div>
  </div>
</template>

<style type="text/scss" scoped>
.logoimg {
  width: 30px;
  margin-right: 15px;
}

.outimg {
  width: 20px;
  height: 20px;
  margin-left: 15px;
}

.logorimg {
  width: 150px;
  height: 30px;
  margin-right: 20px;
}

.headermain {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, #fffeff 0%, #d7fffe 100%);
  /* background-color: #fff; */
  padding: 0 20px;
  box-shadow: 0px 0px 8px 0px rgba(110, 114, 117, 0.5) !important;
}

.headerleft {
  font-size: 24px;
  /* font-family: Alibaba PuHuiTi; */
  font-weight: bold;
  color: #333333;
  line-height: 9px;
  display: flex;
  align-items: center;
}

.headerright {
  text-align: right;
  display: flex;
  align-items: center;
  height: 70px;
  line-height: 70px;
}
</style>

<script>
export default {
  data() {
    return {
      name: localStorage.getItem("username")
    };
  },
  methods: {
    out() {
      localStorage.clear();
      window.location.href = "/";
      // window.location.reload();
    }
  }
};
</script>