<template>
  <div class="sidemain">
    <el-menu :default-active="activetab" class="el-menu-vertical-demo" :router="true" @select="tabactive">
      <a :href="asrc" target="_blank">
        <el-menu-item>
          <i class="iconfont el-icon-odometer" style="margin-right:5px"></i>
          驾驶舱&nbsp;&nbsp;&nbsp;&nbsp;
        </el-menu-item>
      </a>
      <template v-for="(item, index) in menutotal">
        <el-menu-item v-if="item.children.length===0 && !item.hidden" :key="index" :index="item.path">
          <i class="iconfont" :class="item.icon" style="margin-right:5px"></i>
          {{ item.menuName }}
        </el-menu-item>
        <template v-else>
          <el-submenu v-if="!item.hidden" :key="item.id" :index="index + ''">
            <!-- 一级菜单 -->
            <template slot="title">
              <!-- <svg-icon :iconClass="item.meta.icon" :className="item.meta.icon" /> -->
              <i class="iconfont" :class="item.icon" style="margin-right:5px"></i>
              <span slot="title">{{ item.menuName }}</span>
            </template>
            <!-- 子级菜单 -->
            <template v-for="(subItem,index1) in item.children">
              <el-menu-item v-if="!subItem.hidden" :key="index1" :index="subItem.path">
                <!-- <i class="iconfont" :class="item.icon"></i> -->
                {{ subItem.menuName }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
      </template>

      <!-- <el-menu-item index="/unit" active-class="active">
        <i class="el-icon-office-building"></i>
        单位管理
      </el-menu-item>
      <el-menu-item index="/alarm" active-class="active">
        <i class="el-icon-bell"></i>
        告警管理
      </el-menu-item>
      <el-menu-item index="/detail" active-class="active">
        <i class="el-icon-document"></i>
        运行详情
      </el-menu-item>
      <el-menu-item index="/reportlist">
        <i class="el-icon-document-copy"></i>
        运行报告
      </el-menu-item>-->
      <!-- <el-menu-item index="/record">
        <i class="el-icon-menu"></i>
        <router-link to="/record" active-class="active">出入记录</router-link>
      </el-menu-item>
      <el-menu-item index="/statistics">
        <i class="el-icon-menu"></i>
        <router-link to="/statistics" active-class="active">查询统计</router-link>
      </el-menu-item>-->
      <!-- <el-menu-item index="/device" active-class="active">
        <i class="el-icon-monitor"></i>
        设备管理
      </el-menu-item>-->
      <!-- <el-menu-item index="/videoboard">
        <i class="el-icon-menu"></i>
        <router-link to="/videoboard" active-class="active">视频看板</router-link>
      </el-menu-item>-->
      <!-- <el-menu-item index="/user">
        <i class="el-icon-user-solid"></i>
        账号管理
      </el-menu-item>
      <el-menu-item index="/power">
        <i class="el-icon-bell"></i>
        权限管理
      </el-menu-item>
      <el-menu-item index="/log">
        <i class="el-icon-edit-outline"></i>
        日志管理
      </el-menu-item>
      <el-menu-item index="/person">
        <i class="el-icon-user"></i>
        个人中心
      </el-menu-item>-->
    </el-menu>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activetab: localStorage.getItem("activetab"),
      menutotal: [],
      asrc:
        "https://visual.terabits.cn/infant?token=" +
        localStorage.getItem("token") +
        "&name=" +
        localStorage.getItem("username")
    };
  },
  methods: {
    tabactive (e) {
      //console.log(e);
      localStorage.setItem("activetab", e);
    },
    menulist () {
      var arr = JSON.parse(localStorage.getItem("menus"));
      var newarr = [];

      //把设计左边菜单权限筛选出来
      for (var i in arr) {
        if (arr[i].menuType === "C") {
          newarr.push(arr[i]);
        }
      }

      //把父子关系筛选出来
      for (var j in newarr) {
        for (var k in newarr) {
          if (newarr[k].parentId === newarr[j].menuId) {
            newarr[j].children.push(newarr[k]);
          }
        }
      }

      //筛选掉子集菜单
      var lastarr = [];
      for (var l in newarr) {
        if (newarr[l].parentId === 0) {
          lastarr.push(newarr[l]);
        }
      }
      //console.log(newarr);
      this.menutotal = lastarr;
      // //console.log(newarr);
    }
  },
  created () {
    this.menulist();
    if (window.location.origin === "https://child.terabits.cn") {
      this.asrc="https://visual.terabits.cn/infant?token=" +
        localStorage.getItem("token") +
        "&name=" +
        localStorage.getItem("username")
    }
    else if (window.location.origin === "https://uatchild.terabits.cn") {
           this.asrc="https://uatdatav.terabits.cn/infant?token=" +
        localStorage.getItem("token") +
        "&name=" +
        localStorage.getItem("username")
    }else{
       this.asrc="https://csdatav.terabits.cn/infant?token=" +
        localStorage.getItem("token") +
        "&name=" +
        localStorage.getItem("username")
    }
  }
};
</script>

<style scoped>
.active {
  color: white;
}
.el-menu .is-active {
  background: url("https://statics.terabits.cn/mainimg/back12.png") no-repeat;

  /* background: linear-gradient(90deg, #41bdba 0%, #88d6d4 100%); */
  border-radius: 0px 6px 6px 0px;
  /* background-size: contain; */
  color: white !important;
  /* display: block; */
  width: 100%;
  /* height: 56px; */
  /* width: 260px !important;
  max-width: 260px !important;
  min-width: 260px !important; */
}

::v-deep .el-submenu__title:hover {
  background: white;
}

.el-menu-item {
  font-size: 16px;
  padding: 0 !important;
  text-align: center;
}

::v-deep .el-submenu__title {
  text-align: center;
  font-size: 16px;
}

::v-deep div.el-submenu__title {
  background: white;
}

::v-deep div.el-submenu__title span {
  color: #303133 !important;
}

.el-menu--inline .el-menu-item {
  padding-left: 20px !important;
}

.el-aside {
  overflow: inherit;
}
</style>
