const common = {
  state: {
    allTree: [],
    disposeTree: []
  },

  mutations: {
    SET_ALL: (state, allTree) => {
      state.allTree = allTree
    },
    SET_DISPOSE: (state, disposeTree) => {
      state.disposeTree = disposeTree
    }
  },

  actions: {
    // 处理省市区数据
    async dealTree ({ commit }, treeList) {
      commit('SET_ALL', treeList)
      const arr = await dealData(treeList)
      const list = await getTreeData(arr)
      // console.log('treeList :>> ', list[0].children);
      if (list.length === 1) {
        commit('SET_DISPOSE', list[0].children)
      } else {
        commit('SET_DISPOSE', list)
      }

    },
  }
}
// 根据数组精确到省市区
function dealData (val) {
  let arr = JSON.parse(JSON.stringify(val))
  // console.log('arr :>> ', arr);
  if (arr.length === 1) {
    if (arr[0].children) {
      arr = JSON.parse(JSON.stringify(arr[0].children))
      dealData(arr)
    }
  }
  return arr;
}
// 去除children里的空数组
function getTreeData (data) {
  // 循环遍历json数据
  for (var i = 0; i < data.length; i++) {
    if (data[i].children.length < 1) {
      // children若为空数组，则将children设为undefined
      data[i].children = undefined;
    } else {
      // children若不为空数组，则继续 递归调用 本方法
      getTreeData(data[i].children);
    }

    if (!data[i].adcode) {
      data[i].adcode = data[i].id;
    }
  }
  return data;
}

export default common
